import React from 'react';
import styled from 'styled-components';

const TextContainer = styled.div`
  font-family: 'FolioBT-Bold', 'HelveticaNeue-Bold', 'Helvetica Neue Bold',
    'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;

  max-width: 600px;

  margin: ${props => props.margin || '0 auto'};
`;

const ProjectTitle = props => (
  <TextContainer margin={props.margin}>{props.text}</TextContainer>
);

export default ProjectTitle;
