import React from 'react';
import Textbox from '../text/Textbox';
import ProjectTitle from '../text/ProjectTitle';
import styled from 'styled-components';
import EventProjectContainer from '../global/EventProjectContainer';

const SingleImageContainer = styled.div`
  width: 100%;

  margin: 0 auto;

  & img {
    margin-bottom: 32px;
    object-fit: ${props => (props.objectFit ? props.objectFit : 'cover')};
    height: ${props => (props.height ? props.height : 'auto')};
  }

  @media (max-width: 767px) {
    & img {
      height: ${props => (props.height ? 'calc(100vw - 40px)' : 'auto')};
    }
  }
`;

const SingleImageProject = ({ image, height, objectFit }) => (
  <SingleImageContainer height={height} objectFit={objectFit}>
    <img
      src={image}
      // srcSet={`${image}&h=400&crop=entropy&q=80 1x,
      // ${image}&h=400&crop=entropy&dpr=2&q=80 2x,
      // ${image}&h=400&crop=entropy&dpr=3&q=80 3x`}
      // src={`${image}&h=400&crop=entropy&q=80`}
    />
  </SingleImageContainer>
);

export default SingleImageProject;
