import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'styled-bootstrap-grid';

import { graphql } from 'gatsby';
import LazyLoad from 'react-lazyload';
import Helmet from 'react-helmet';

// Components
import SingleImageProject from '../events/SingleImageProject';
import Textbox from '../text/Textbox';

const PublicationTitle = styled.h1`
  font-size: 28px;
  text-align: center;

  margin-top: 75px;
  margin-bottom: 27px;

  @media (max-width: 767px) {
    font-size: 27px;
  }

  @media (max-width: 420px) {
    margin-top: 0;
    font-size: calc(21px + (28 - 21) * ((100vw - 300px) / (420 - 300)));
  }
`;

const Event = ({ data: { prismicEvent } }) => {
  const { data } = prismicEvent;

  let displayProject = data.body.map((project, index) => {
    if (project.slice_type === 'title') {
      return (
        <Col col={12} key={`project_content_${index}`}>
          <Textbox
            margin={index === 0 ? `20px auto` : `122px auto 20px auto`}
            mobileMargin={`40px auto 20px auto`}
            textAlign={`center`}
            text={project.primary.title1.html}
          />
        </Col>
      );
    }

    if (project.slice_type === 'text') {
      return (
        <Col col={12} key={`project_content_${index}`}>
          <Textbox
            margin={`0 auto 27px auto`}
            mobileMargin={`0 auto 27px auto`}
            text={project.primary.project_text.html}
            paragraphTopMargin={`-5px`}
          />
        </Col>
      );
    }

    if (project.slice_type === 'image') {
      if (
        project.primary.project_image.dimensions.width >
          project.primary.project_image.dimensions.height ||
        project.primary.project_image.dimensions.width ===
          project.primary.project_image.dimensions.height
      ) {
        return (
          <Col col={12} key={`project_content_${index}`}>
            <LazyLoad height={400}>
              <Row justifyContent="center">
                <Col col={12} md={8}>
                  <SingleImageProject
                    image={project.primary.project_image.url}
                  />
                </Col>
              </Row>
            </LazyLoad>
          </Col>
        );
      } else {
        return (
          <Col col={12} key={`project_content_${index}`}>
            <LazyLoad height={400}>
              <Row justifyContent="center">
                <Col col={12} md={8}>
                  <SingleImageProject
                    image={project.primary.project_image.url}
                    height={`calc(66.667vw - 80px)`}
                    objectFit={`contain`}
                  />
                </Col>
              </Row>
            </LazyLoad>
          </Col>
        );
      }
    }
  });

  return (
    <>
      <Helmet title={`${data.title.text} - Askeaton Contemporary Arts`} />
      <Col col={12}>
        <PublicationTitle>
          {data.title.text}
          <br />
          {data.date}
          <br />
          {data.location}
        </PublicationTitle>
      </Col>

      <Col col={12}>
        <Row justifyContent="center">{displayProject}</Row>
      </Col>
    </>
  );
};

export default Event;

export const pageQuery = graphql`
  query EventBySlug($uid: String!) {
    prismicEvent(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        date
        location
        text {
          html
        }
        body {
          ... on PrismicEventBodyTitle {
            id
            slice_type
            primary {
              title1 {
                html
              }
            }
          }
          ... on PrismicEventBodyText {
            id
            slice_type
            primary {
              project_text {
                html
              }
            }
          }
          ... on PrismicEventBodyImage {
            id
            slice_type
            primary {
              project_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  }
`;
