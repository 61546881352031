import React from 'react';
import styled from 'styled-components';

const ContentContainerStyle = styled.div`
  position: relative;

  margin: 0 auto;
  padding: ${props => props.padding || '0 0 60px 0'};

  &:last-of-type {
    padding-bottom: 40px;
  }
`;

const EventProjectContainer = ({ children, padding }) => (
  <ContentContainerStyle padding={padding}>{children}</ContentContainerStyle>
);

export default EventProjectContainer;
